/* eslint-disable @typescript-eslint/ban-ts-comment */
import { usePageTracker } from '@/analytics'
import { ReactComponent as EmailSentIcon } from '@/assets/icons/ic-email-sent.svg'
import AppleSignInButton from '@/components/Button/AppleSignInButton'
import GoogleSignInButton from '@/components/Button/GoogleSignInButton'
import { LoadingButton } from '@/components/common/Form/LoadingButton'
import BackLink from '@/components/Links/BackLink'
import PrivacyPolicyLink from '@/components/Links/PrivacyPolicyLink'
import { useAuth } from '@/reducers/user'
import { requestMagicLink, signUp } from '@/services/auth'
import useIsMobile from '@/utils/isMobile'
import {
  agreedValidator,
  emailValidator,
  fullNameValidator,
  passwordErrorText,
  passwordValidator
} from '@/utils/validators'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Box, Divider, styled, Typography, useTheme } from '@mui/material'
import { AxiosError } from 'axios'
import { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { object } from 'yup'
import FormCheck from '../../components/Form/Check'
import FormInput from '../../components/Form/Input'
import { SignUpPageLayout } from './BaseLayout'
import QuickSignUpPage from './QuickSignup'

type ErrorCode = keyof typeof EMAIL_ERROR_MESSAGES

interface FormValues {
  fullName: string
  email: string
  password: string
  agreed: boolean
}

const schema = object({
  fullName: fullNameValidator,
  agreed: agreedValidator,
  password: passwordValidator,
  email: emailValidator
}).required()

const StyledEmailSentIcon = styled(EmailSentIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
  display: 'block',
  margin: '0 auto'
}))

const trackingPayload = { page: 'Sign Up' } as const

const ReCAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY

const EMAIL_ERROR_MESSAGES = {
  409: `This email address has already been registered. 
        Please use a different one, try logging in or <a href="/auth/forget-password" target="_blank" rel="noopener noreferrer">reset your password</a>.`,
  403: `It looks like this email address has already been used to create an account. 
        Please use a different email address to register. 
        If you want to reactivate your account, <a href="https://helloarcher.io/contact-us/" target="_blank" rel="noopener noreferrer">contact our support team</a> 
        for assistance or use the chatbot available on our platform for immediate help.`
} as const

export const useSignUpPage = (defaultEmail?: string) => {
  const { state } = useLocation()
  const { user, revalidate } = useAuth()

  const [searchParams] = useSearchParams()

  const form = useForm<FormValues>({
    defaultValues: {
      fullName: '',
      email: defaultEmail || state?.defaultEmail || searchParams.get('defaultEmail') || '',
      password: '',
      agreed: false
    },
    resolver: yupResolver(schema)
  })
  const recaptchaRef = useRef<ReCAPTCHA>()
  const navigate = useNavigate()

  const [recaptchaToken, setRecaptchaToken] = useState<string | undefined>(undefined)
  const [serverError, setServerError] = useState<string | undefined>(undefined)

  const [completed] = useState(false)
  const [emailResent, setEmailResent] = useState(false)
  const [emailAddress, setEmailAddress] = useState<string | undefined>(undefined)

  const { control, handleSubmit, formState, getValues, setError } = form

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setServerError(undefined)
      await signUp(
        {
          fullName: values.fullName,
          email: values.email,
          password: values.password
        },
        {
          headers: {
            'recaptcha-token': recaptchaToken
          }
        }
      )
      setEmailAddress(values.email)
      await requestMagicLink(values.email)
      await revalidate()
    } catch (error) {
      const err = error as AxiosError
      const { status: errorCode } = err?.response || {}

      if (errorCode && errorCode in EMAIL_ERROR_MESSAGES) {
        setError('email', {
          type: 'manual',
          message: EMAIL_ERROR_MESSAGES[errorCode as ErrorCode]
        })
      }

      recaptchaRef.current?.reset()

      // @ts-ignore
      if (error?.response?.status === 400 && error?.response?.data?.errors?.[0]) {
        // @ts-ignore
        const [err] = error?.response?.data?.errors ?? []
        if (err.param === 'email') {
          setError('email', {
            type: 'manual',
            message: err.msg
          })
          return
        }

        // @ts-ignore
        setServerError(error?.response?.data?.errors?.[0])
        return
      }

      // @ts-ignore
      setServerError(error?.response?.data?.error)
    }
  }

  const resendEmail = () => {
    if (emailResent) {
      return
    }

    setEmailResent(true)
  }

  useEffect(() => {
    if (user?._id) {
      navigate('/lookup/address')
    }
  }, [navigate, user])

  return {
    completed,
    control,
    emailAddress,
    emailResent,
    form,
    formState,
    getValues,
    handleSubmit: handleSubmit(handleFormSubmit),
    recaptchaRef,
    resendEmail,
    serverError,
    setEmailResent,
    setRecaptchaToken
  }
}

const SignUpPage = ({ defaultEmail }: { defaultEmail?: string }) => {
  const theme = useTheme()
  usePageTracker('Visits Sign Up Page', trackingPayload)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const {
    completed,
    control,
    emailAddress,
    form,
    formState,
    handleSubmit,
    recaptchaRef,
    resendEmail,
    serverError,
    setRecaptchaToken
  } = useSignUpPage(defaultEmail)

  const { errors } = formState

  return (
    <SignUpPageLayout mobileSideScreen={null}>
      {!completed ? (
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <Typography variant="h1">Quick Sign Up</Typography>
            <div className="h-2"></div>
            <GoogleSignInButton
              className="w-full mt-10 border-[#747775] text-black"
              page="Sign Up"
            />
            <AppleSignInButton className="w-full mt-4 border-[#747775] text-black" page="Sign Up" />

            <Box my={4} sx={{ color: theme.palette.text.secondary }}>
              <Divider>
                <b>OR</b>
              </Divider>
            </Box>

            <div className="h-2"></div>
            <FormInput
              className="font-medium text-[#515468]"
              control={control}
              name="fullName"
              label="FULL NAME"
              controlId="fieldFullName"
              rules={{ required: true }}
              error={errors?.fullName?.message}
            />
            <FormInput
              className="font-medium text-[#515468]"
              control={control}
              name="email"
              type="email"
              label="EMAIL ADDRESS"
              controlId="fieldEmailAddress"
              rules={{ required: true }}
              error={
                errors?.email?.message && (
                  <div dangerouslySetInnerHTML={{ __html: errors?.email?.message ?? '' }} />
                )
              }
            />
            <FormInput
              className="font-medium text-[#515468]"
              control={control}
              name="password"
              label="PASSWORD"
              type="password"
              controlId="fieldPassword"
              helperText={errors?.password?.message ? undefined : passwordErrorText}
              rules={{ required: true }}
              error={errors?.password?.message}
            />

            <FormCheck
              control={control}
              name="agreed"
              label={
                <div>
                  I have read and agree to the <PrivacyPolicyLink /> and{' '}
                  <PrivacyPolicyLink label="Terms of Service" />
                </div>
              }
              controlId="fieldAgreedCheck"
              rules={{ required: true }}
              error={errors?.agreed?.message}
            />
            {!!serverError && <Alert severity="error">{serverError}</Alert>}
            {ReCAPTCHA_KEY ? (
              <ReCAPTCHA
                // @ts-ignore
                ref={recaptchaRef}
                sitekey={ReCAPTCHA_KEY}
                onChange={(token) => {
                  if (token) {
                    setRecaptchaToken(token)
                  }
                }}
                className="w-full mt-4"
              />
            ) : null}
            <LoadingButton
              className="w-full mt-6"
              track={{ event: 'Clicks Sign Up', page: 'Sign Up' }}
            >
              Sign up
            </LoadingButton>
            <div className="mx-auto text-center text-sm font-medium mt-8">
              Already have an account?&nbsp;
              <Link to="/auth/sign-in" className="no-underline mt-6 lg:mt-10 text-primary.main">
                Log in
              </Link>
            </div>
          </form>
        </FormProvider>
      ) : null}
      {completed ? (
        <div className="w-100 h-100 d-flex flex-column justify-content-between">
          <form onSubmit={handleSubmit} className="w-100">
            <Typography variant="h1">Email verification</Typography>
            <Divider className="mt-4 mb-8" />
          </form>
          <Box mb={8}>
            <Box ml="auto" mr="auto" mb={3}>
              <StyledEmailSentIcon />
            </Box>
            <Typography textAlign={'center'} variant="h3" mb={2}>
              Check your email inbox!
            </Typography>
            <Typography className="text-center lg:text-left">
              Please check the email we sent to&nbsp;
              {emailAddress ? <b>{emailAddress}</b> : 'your email address'} to activate your
              account.
              <br />
              <br />
              If you don&apos;t receive the email within a few minutes, check your spam folder.
            </Typography>
            <br />
            <br />
            <Typography className="text-center lg:text-left">
              Still haven&apos;t received it? Click&nbsp;
              <a
                role="button"
                href={`/auth/magic-link/request?email=${emailAddress}`}
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  resendEmail()
                }}
              >
                here
              </a>
              &nbsp;to resend.
            </Typography>
          </Box>
          <div className="d-flex align-items-center justify-content-center">
            <BackLink>Back to Login</BackLink>
          </div>
        </div>
      ) : null}
    </SignUpPageLayout>
  )
}

const SignUpPages = () => {
  const isMobile = useIsMobile(undefined, 1024)
  const [defaultEmail, setDefaultEmail] = useState<string | undefined>(undefined)

  if (isMobile && !defaultEmail) {
    return <QuickSignUpPage onComplete={(defaultEmail) => setDefaultEmail(defaultEmail)} />
  }

  return <SignUpPage defaultEmail={defaultEmail} />
}

export default SignUpPages
